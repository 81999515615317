/* eslint-disable react/prop-types */
// Components
import React from 'react';
// Components
import Hero from '../components/Hero/Hero';
import SEO from '../components/SEO/SEO';
import Link from '../components/Link/Link';
import MicroLayout from '../layouts/Micro';

// Utils
import titleCase from '../utils/titleCase';

const BlogCategories = ({ pageContext, location }) => {
  const originalData = pageContext.appData;
  const filterTerm = location.pathname.split('/app-marketplace/')[1].replace('/', '');
  const modifiedData = originalData.filter((data) => data.app_tag.toString() === filterTerm);
  return (
    <MicroLayout>
      <SEO title={`${titleCase(filterTerm.replace(/-+/, ' '))}`} />

      <Hero
        title={titleCase(filterTerm.replace(/-+/, ' '))}
        location={location}
        crumbLabel={filterTerm}
        isNeedMainLabel={false}
      />

      <div className="container my-1">
        <div className="row flex-center">
          <div className="container my-1">
            <div className="row flex-center text-center my-1">
              <div className="container my-1">
                <div className="row flex-center text-center my-1">
                  {modifiedData.map((data) => (
                    <div
                      className="col-lg-2 col-md-2 col-sm-3 col-xs-6"
                      key={Math.random().toString(36).substr(2, 9)}
                    >
                      <div className="tagListItem p-2 mb-3 m-1">
                        <Link href={`/app-marketplace/${data.app_title.toLowerCase()}`}>
                          <img loading="lazy" src={data.app_logo} width={64} alt={data.app_title} />
                          <p className="tagListItem-text text-bold text-capitalize text-dark my-1">
                            {data.app_title.replace(/-/g, ' ')}
                          </p>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MicroLayout>
  );
};

export default BlogCategories;
